import {useEffect, useState} from 'react'

import {handleHttpError} from './error'
import {BACKEND, AUTH_BACKEND} from '~/config'
import {createObjectMap} from '~/utils/createObjectMap'
import {getUseSharedReducer} from '~/utils/getUseSharedReducer'
import {getUseSharedState} from '~/utils/getUseSharedState'
import {http} from '~/utils/http'

import type {BackendError} from './error'
import type {ProjectSettingsTableColumn} from './project'
import type {Account} from '~/services/account'

export type PersonalSettings = {
    lang?: string
    start_project_id?: string
    show_lag?: boolean
    lag_threshold?: number
    access_item?: string
    code?: string
    email?: string
    login?: string
    name?: string
    phoneNumber?: string
    users?: string
    big_icons?: boolean
    comment_color?: string
    codeEditorOptions?: {
        showLineNumber?: boolean
        lineWrapping?: boolean
    }
    column_settings?: {
        [key: string]: ColumnSettings
    }
}

export type ColumnSettings = {
    excluded_columns?: string[]
    columns?: Record<string, ProjectSettingsTableColumn | undefined>
}

export type ProfileRole = 'ACCOUNT_ADMIN' | 'SUPER_ADMIN' | 'ACCOUNT_USER'

export type User = {
    account_id: string
    account_role_id?: string
    active: boolean
    global_role: ProfileRole
    id: string
    username: string
    actual_username?: string
    email?: string
    is_confirmed: boolean
    created_on: string
    modified_on?: string
    projects?: {
        id: string
        name: string
        role_id: string
    }[]
    two_factor: boolean
    personal_settings?: PersonalSettings
}

export type UserForCommentGroup = {
    id: string
    username: string
    account_role_id?: string
    document_user_settings: {
       owner?: boolean
       worker?: boolean
    }
}

export type Operator = {
    id: string
    name: string
}

export type Role = {
    account_id: string
    id: string
    name: string
    permissions: string[]
    priority?: number
}

export type AuthState = {
    profile?: User
    account?: Account
    permissions: PermissionMap
    ready: boolean
}

const initialAuthState: AuthState = {
    profile: undefined,
    account: undefined,
    permissions: {},
    ready: true,
}

type AuthAction =
    | {
        type: 'login'
        payload: Partial<AuthState>
    }
    | {type: 'logout'}
    | {
        type: 'update'
        payload: Partial<AuthState>
    }
    | {
        type: 'setReady'
        payload: boolean
    }

function reducer(state: AuthState, action: AuthAction): AuthState {
    switch (action.type) {
    case 'update':
        return {
            ...state,
            ...action.payload,
            ready: true,
        }
    case 'login':
        return {
            permissions: {},
            ...action.payload,
            ready: true,
        }

    case 'logout':
        return initialAuthState

    case 'setReady':
        return {
            ...state,
            ready: action.payload,
        }

    default:
        return state
    }
}

export const useAuth = getUseSharedReducer(reducer, initialAuthState)

// TODO: отрефакторить
export function getAuth(): Promise<Required<AuthState>> {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return Promise.all([
        http.get<Account>(`${BACKEND}/`),
        http.get<User>(`${BACKEND}/profile`),
        getAllPermissions(),
    ])
        .then(([account, profile, permissions]) => {
            const result = {
                account,
                profile,
                permissions: account.permissions
                    ? getPermissionMap(account.permissions)
                    : getPermissionMap(permissions),
                ready: true,
            }

            useAuth.dispatch({
                type: 'login',
                payload: result,
            })

            return result
        })
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: 'setReady',
            payload: true,
        }))
}

export type Permission =
    | 'account'
    | 'additional_weight_read'
    | 'additional_weight_write'
    | 'alerts'
    | 'categories'
    | 'categories_read'
    | 'categories_read_extra'
    | 'llm_forms'
    | 'llm_forms_read'
    | 'llm_forms_read_extra'
    | 'charts'
    | 'charts_read'
    | 'charts_shared'
    | 'dashboards'
    | 'dashboards_read'
    | 'dashboards_shared'
    | 'debug'
    | 'doclibs'
    | 'document_export'
    | 'document_task'
    | 'document_history'
    | 'download_audio_file'
    | 'file_download_storage'
    | 'entity_types'
    | 'frontendreports'
    | 'label_view'
    | 'monitoring' // используется только на TP 1.5
    | 'oauth_client'
    | 'orgstruct'
    | 'private_labels_seen_modify'
    | 'push'
    | 'push_notifications'
    | 'queries'
    | 'queries_read'
    | 'queries_shared'
    | 'reports'
    | 'reports_read'
    | 'reports_shared'
    | 'roles'
    | 'root_projects' // используется только на TP 1.5
    | 's2t_tuning' // используется только на TP 1.5
    | 'simple_chains'
    | 'semantic_search'
    | 'speaker'
    | 'speaker_shared'
    | 'spell_checker'
    | 'statistics' // используется только на TP 1.5
    | 'storage_delete'
    | 'tapes'
    | 'tapes_read'
    | 'tapes_shared'
    | 'train_phrase'
    | 'users'
    | 'user_activate'
    | 'checklist_inner'
    | 'telegram_group_writes'
    | 'telegram_group_reads'
    | 'show_rating_keys'
    | 'llm'
    | 'user_score_flags_read'
    | 'ad_integration'
    | 'white_board'
    | 'user_actions_read'
    | 'document_zone_policies'
    | 'personal_data_mask_config_read'
    | 'personal_data_mask_config_write'
    | 'document_access_disable'
    | 'ai_assistant_read'
    | 'ai_assistant_write'

//TODO удалить 'search' из массива, когда бэк удалит его у себя
export const tp15permissions = ['root_projects', 's2t_tuning', 'statistics', 'monitoring', 'search']

export function getAllPermissions(): Promise<Permission[]> {
    return http.get<Permission[]>(`${BACKEND}/permissions`)
        .then(permissions => permissions.filter(permission => !tp15permissions.includes(permission)))
        .catch(() => [])
}

export function patchProfileSettings(profile: Partial<User>) {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return http.patch<User>(`${BACKEND}/profile`, profile)
        .then(result => {
            getAuth()

            if (useAuth.getState().profile?.personal_settings?.lang != profile.personal_settings?.lang)
                window.location.reload()

            return result
        })
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: 'setReady',
            payload: true,
        }))
}

type LoginData = {
    username: string
    password: string
    remember_me: boolean
}

export function login(loginData: LoginData) {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return http.post<{code?: number, email?: string}>(`${AUTH_BACKEND}/login`, loginData)
        .then(result => {
            getAuth()
            return result
        })
        .then(result => {
            if (!result.code && !result.email) {
                broadcastChannel.postMessage('reload')
                location.reload()
            }

            return result
        })
        .catch(({error}) => {
            useAuth.dispatch({type: 'logout'})
            throw error
        })
        .finally(() => useAuth.dispatch({
            type: 'setReady',
            payload: true,
        }))
}

export function login2Factor(loginData: string) {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return http.post(`${AUTH_BACKEND}/login/code`, {code: loginData})
        .then(result => {
            getAuth()
            return result
        })
        .then(result => {
            broadcastChannel.postMessage('reload')
            location.reload()
            return result
        })
        .catch(({error}) => {
            useAuth.dispatch({type: 'logout'})
            throw error
        })
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: 'setReady',
            payload: true,
        }))
}

export function loginSpecial() {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return http.get(`${AUTH_BACKEND}/login/sso`)
        .then(result => {
            getAuth()
            return result
        })
        .then(result => {
            broadcastChannel.postMessage('reload')
            location.reload()
            return result
        })
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: 'setReady',
            payload: true,
        }))
}

export function loginAs(userId: string) {
    return http.post(`${BACKEND}/users/${userId}/login`)
        .catch(handleHttpError)
}

export function logoutAs() {
    return http.post(`${BACKEND}/users/logout`)
        .catch(handleHttpError)
}

export function logout() {
    useAuth.dispatch({
        type: 'setReady',
        payload: false,
    })

    return http.post(`${AUTH_BACKEND}/logout`)
        .finally(() => {
            useAuth.dispatch({type: 'logout'})
            broadcastChannel.postMessage('reload')
        })
}

const broadcastChannel = new BroadcastChannel('channel')

broadcastChannel.onmessage = event => {
    if (event.data === 'reload') {
        broadcastChannel.close()
        location.reload()
    }
}

export function logoutAndReload() {
    return logout()
        .finally(() => location.reload())
}

export function restorePassword(username: string) {
    return http.put(`${BACKEND}/profile/password/reset`, {
        username,
        reset_url: `${window.location.origin}/login/password/reset?token=`,
    })
        .catch(handleHttpError)
}

export function resetPassword(password: string, token: string) {
    return http.post(`${BACKEND}/profile/password/reset`, {password}, {token})
        .catch(handleHttpError)
}

export function resetUserPassword(id: string) {
    return http.post(`${BACKEND}/users/${id}/password/reset`)
        .then(refreshUserList)
        .catch(handleHttpError)
}

let userListCache: {[key in string]?: Promise<User[]>} = createObjectMap()

const userListChangeDispatcher = getUseSharedState({})

function refreshUserList() {
    userListCache = createObjectMap()
    userListChangeDispatcher.dispatch({})
}

export function getUsers(global?: boolean, accountId?: string) {
    const key = `${global}-${accountId}`

    return userListCache[key] ||=
        http.get<User[]>(`${BACKEND}/users`, {
            scope: global ? 'global' : 'local',
            account_id: accountId,
        })
            .catch(handleHttpError)
            .catch(error => {
                delete userListCache[key]
                throw error
            })
}

export function useUsers(
    {global, accountId, errorHandler}:
    {global?: boolean, accountId?: string, errorHandler?: (error: BackendError) => void}
) {
    const [users, setUsers] = useState<User[]>([])

    useEffect(
        () => {
            function refreshUsers() {
                getUsers(global, accountId)
                    .then(setUsers)
                    .catch(errorHandler)
            }

            refreshUsers()

            return userListChangeDispatcher.subscribe(refreshUsers)
        },
        [global, accountId, errorHandler]
    )

    return users
}

export function createUser(user: Partial<User>) {
    return http.post(`${BACKEND}/users`, user)
        .then(refreshUserList)
        .catch(handleHttpError)
}

const userCache = createObjectMap<string, Promise<User> | undefined>()

export function getUser(userId: string) {
    return userCache[userId] ||= http.get<User>(`${BACKEND}/users/${userId}`)
        .catch(handleHttpError)
        .catch(error => {
            delete userCache[userId]
            throw error
        })
}

export function patchUser(id: string, user: Partial<User>) {
    return http.patch(`${BACKEND}/users/${id}`, user)
        .then(refreshUserList)
        .catch(handleHttpError)
}

export function deleteUser(id: string) {
    return http.delete(`${BACKEND}/users/${id}`, {})
        .then(refreshUserList)
        .catch(handleHttpError)
}

export function addProjectUser(projectId: string, user: {id: string, role_id: string}) {
    return http.patch(`${BACKEND}/projects/${projectId}/users`, [user])
        .finally(refreshUserList)
        .catch(handleHttpError)
}

export function deleteProjectUser(projectId: string, userId: string) {
    return http.delete(`${BACKEND}/projects/${projectId}/users`, {ids: userId})
        .finally(refreshUserList)
        .catch(handleHttpError)
}

export function activateUser(id: string) {
    return http.post(`${BACKEND}/users/${id}/activate`, {})
        .finally(refreshUserList)
        .catch(handleHttpError)
}

export function deactivateUser(id: string) {
    return http.post(`${BACKEND}/users/${id}/deactivate`, {})
        .finally(refreshUserList)
        .catch(handleHttpError)
}

export type Subject = {
    id: string
    account_id: string
    name: string
    type: SubjectType
    created_on: string
    modified_on: string
}

export type SubjectType = 'user' | 'group'
export type ToolType = 'dashboards' | 'charts' | 'queries'
export type ToolSubjectData = {
    filter: {
        ids: string[]
        path: string
        project_id?: string
    }
}
export type ToolWithSubjects = {
    id: string
    subjects: Subject[]
}

export type UsernameSuggest = {
    username: string
}

export function getUsernameSuggests() {
    return http.get<{data: UsernameSuggest[]}>(`${BACKEND}/users/username-suggests`)
        .then(({data}) => data.sort((a, b) => a.username.localeCompare(b.username)))
        .catch(handleHttpError)
}

//TODO: хорошо бы кэшировать эту информацию
export function getSubjects() {
    return http.get<{data: Subject[]}>(`${BACKEND}/subjects`)
        .then(({data}) => data)
        .catch(handleHttpError)
}

export function getToolsSubjects(type: ToolType, data: ToolSubjectData) {
    return http.post<{tools: ToolWithSubjects[]}>(`${BACKEND}/tools/${type}/shared/subjects/search`, data)
        .then(({tools}) => tools)
        .catch(handleHttpError)
}

export function updateToolsSubjects(type: ToolType, data: ToolSubjectData) {
    return http.put(`${BACKEND}/tools/${type}/shared/subjects`, data)
        .catch(handleHttpError)
}

export function updateToolsSubjectsBatch(type: ToolType, subjectBatchData: ToolSubjectData[]) {
    return Promise.all(subjectBatchData.map(subjectData => updateToolsSubjects(type, subjectData)))
        .catch(handleHttpError)
}

export function getUsersAuthority(accountId?: string) {
    return http.get<{data: Operator[]}>(`${BACKEND}/users/authority`, {account_id: accountId})
        .then(({data}) => data)
        .catch(handleHttpError)
}

export function getUserAuthority(id: string) {
    return http.get<{access_items?: string[]}>(`${BACKEND}/users/${id}/authority`)
        .then(({access_items: accessItems}) => accessItems || [])
        .catch(handleHttpError)
}

export function updateUserAuthority(id: string, data: {access_items: string[]}) {
    return http.put(`${BACKEND}/users/${id}/authority`, data)
        .catch(handleHttpError)
}

const TYPE_URLS = {
    account: '',
    project: 'projects/',
} as const

// TODO дублирование метода getAccountRoles
export function getRoles(type: keyof typeof TYPE_URLS) {
    return http.get<Role[]>(`${BACKEND}/${TYPE_URLS[type]}roles`)
        .catch(handleHttpError)
}

export function sendFeedback(name: string, email: string, problem: string, files: File[] = []) {
    const formData = new FormData()
    formData.append('data', JSON.stringify({
        metadata: {name, email},
        text: problem,
        userAgent: navigator.userAgent,
    }))
    files.forEach((file, index) => formData.append(`attachment_${index}`, file))
    return http.post(`${BACKEND}/feedback-extended`, formData)
        .catch(handleHttpError)
}

export type PermissionMap = {
    [permission in Permission]?: true
}

function getPermissionMap(permissions: Permission[]): PermissionMap {
    return Object.fromEntries(permissions.map(permission => [permission, true]))
}

export function getUsersForFlag(projectId: string, documentId: string, commentGroupId: string) {
    return http.get<{data: UserForCommentGroup[]}>(
        `${BACKEND}/projects/${projectId}/realtime/documents/${documentId}/comment-groups/${commentGroupId}/users`
    )
        .then(({data}) => data)
        .catch(handleHttpError)
}
